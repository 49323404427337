<template>
  <div class="main">
   
    <div class="msgbox">
      <div class="topbox">
        <img src="../assets/img/girl.png" alt="" />
        <span>王俊丽</span>
      </div>
      <div class="textbox" id="new_message">
        <div v-for="(item, i) in mewsList" :key="i">
          <div class="othernews" v-if="!item.ismine">
            <div class="namebox">
              {{ item.user }} <span>{{ item.time }}</span>
            </div>
            <div class="nexsbox">
              <span>{{ item.msg }}</span>
            </div>
          </div>

          <div class="othernews"  v-if="item.ismine">
            <div class="minebox">   {{ item.user }} <span>{{ item.time }}</span></div>
            <div class="newsbox"><span>{{ item.msg }}</span></div>
          </div>
        </div>
      </div>
      <div class="inputbox">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入内容"
          v-model="textarea"
        >
        </el-input>

        <div class="sendbox">
          <span @click="seedmsg">发送</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 公共样式
import "../assets/css/common.css";
export default {
  data() {
    return {
      textarea: "",
      mewsList: [
        {
          msg:
            "暗红色的卡号是肯定啊是的咯技术的卡仕达卡仕达卡萨丁和咯技术的卡省的看",
          ismine: false,
          time: "2021-05-01",
          user: "流氓",
        },
        {
          msg:
            "暗红色的卡号是肯定啊是的咯技术的卡仕达卡仕达卡萨丁和",
          ismine: true,
          time: "2021-05-01",
          user: "良民",
        },
        {
          msg:
            "暗红色的卡号是肯定啊是的咯技术的卡仕达卡仕达卡萨丁和咯技术的卡省的看",
          ismine: false,
          time: "2021-05-01",
          user: "流氓",
        },
        {
          msg:
            "暗红色的",
          ismine: true,
          time: "2021-05-01",
          user: "良民",
        },
        {
          msg:
            "暗红色的卡号是肯定啊是的咯技术的卡仕达卡仕达卡萨丁和咯技术的卡省的看",
          ismine: false,
          time: "2021-05-01",
          user: "流氓",
        },
      ],
    };
  },
  watch: {
    windowWidth(val) {
      let that = this;
      // console.log("实时屏幕宽度：", val);
      if (val > 990) {
        that.drawer = false;
        that.height = "400px";
      } else if (val <= 900 && val > 400) {
        that.height = "200px";
      } else {
        that.height = "100px";
      }
    },
  },
  methods: {
    // 获取消息列表
    getnewsList(id) {
      let that = this;
      that
        .$post("单个消息列表", {
          SysID: id,
        })
        .then(function (data) {
          console.log(data);
          let datalist = data.Obj.Obj;
          let arr = datalist.Tags;
          datalist.Tags = arr.split(",");
          console.log(arr);
          that.obj = datalist;
          let company = data.Obj.CompanyObj;
          company.Logo = that.urlOA + company.Logo.substr(1);
          that.compObj = company;

          that.otherlist = data.Obj.AdvertiseList;
          let RecommendList = data.Obj.RecommendList;
          RecommendList.forEach((v) => {
            v.CompanyLogo = that.urlOA + v.CompanyLogo.substr(1);
          });
          that.RecommendList = RecommendList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    scrollToBottom: function () {
    this.$nextTick(() => {
        var container = this.$el.querySelector("#new_message");
        container.scrollTop = container.scrollHeight;
    });
},
    // 发送消息
    seedmsg(id) {

 let that = this;
 let text = that.textarea
 if (!text.trim()) {
     return
 }
      let msg=     {
          msg:text,
            
          ismine: true,
          time: "2021-05-01",
          user: "良民",
        }
        that.mewsList.push(msg)
        that.textarea=''
     that.scrollToBottom()
    //   that
    //     .$post("/api/IMMessage/Add", {
    //       Receiver: "c8efc2fc-47e1-4e19-b9c1-1226968879e5",
    //       Info: "哦阿婆的撒平时都埃松皮蒂阿炮地啊上帝啊山坡i安排送抵爱普生",
    //       CompanySysID: "11",
    //     })
    //     .then(function (data) {
    //       console.log(data);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    },
  },
  mounted() {
  
    let SysID = this.$route.query.SysID;
    console.log(SysID);
    var that = this;
       that.scrollToBottom()
    that.getnewsList(SysID);
    let windowWidth = that.windowWidth;
    console.log(windowWidth);
    if (windowWidth < 900 && windowWidth > 400) {
      that.height = "200px";
    } else if (windowWidth < 400) {
      that.height = "150px";
    }
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
};
</script>


<style scoped  lang="scss">
.msgbox {
  //   height: 550px;
  width: 520px;
  // background-color: aqua;
  margin-left: 50%;
  transform: rotateX(-50%);
  border: 1px solid #dee0e3;
  margin-bottom: 10px;
}
.topbox {
    position: relative;
  height: 80px;
  background-color: rgb(161, 212, 253);
  overflow: hidden;
  img {
    float: left;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin-top: 10px;
    margin-left: 10px;
  }
  span {
    float: left;
    height: 80px;
    line-height: 80px;
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
    color: #222;
  }
  .cloese{
     position: absolute;
      cursor: pointer;
  }
}
.textbox {
  height: 340px;
  overflow: auto;
}
.othernews {
  // background: blueviolet;
  padding: 6px 10px;
  box-sizing: border-box;
}
.namebox {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  width: 460px;
  margin-left: 50%;
  transform: translateX(-50%);
  span {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #888;
  }
}
.minebox {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: right;
  width: 460px;
  margin-left: 50%;
  transform: translateX(-50%);
  span {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #888;
  }
}

.nexsbox {
  width: 460px;
  margin-left: 50%;
  transform: translateX(-50%);
  overflow: hidden;

  font-size: 14px;

  box-sizing: border-box;
  border-radius: 3px;
  span {
    float: left;
    background-color: #dee0e3;
    line-height: 26px;
    padding: 6px 10px;
    border-radius: 3px;
  }
}
.newsbox {
  width: 460px;
  margin-left: 50%;
  transform: translateX(-50%);
  overflow: hidden;

  font-size: 14px;

  box-sizing: border-box;

  span {
    float: right;
    background-color: #cce4fc;
    line-height: 26px;
    padding: 6px 10px;
    border-radius: 3px;
  }
}
.inputbox {
  //   height: 160px;
  //   background-color: blueviolet;
  border-top: 1px solid #eee;
  ::v-deep .el-textarea__inner {
    border: none;
    border-radius: 0;
    resize: none;
    height: 90px;
    line-height: 30px;
  }
}
.sendbox {
  // height: 40px;
  overflow: hidden;
  // background-color: brown;

  span {
    float: right;
    padding: 3px 20px;
    background-color: #008cff;
    border-radius: 3px;
    font-size: 14px;
    color: #fff;
    margin-right: 20px;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
</style>